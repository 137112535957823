<template>
  <div class="container w-full md:w-[1472px] mx-auto mt-120">
    <div class="flex justify-between">
      <div class="flex flex-col flex-none w-full sm:w-536">
        <h1 class="">{{ $t('imprint.title') }}</h1>
      </div>
      <img src="img/Impressum.jpg" class="flex-grow-0 h-auto" alt="" />
    </div>
    <div class="my-16">
      <h3>{{ $t('imprint.responsible') }}</h3>
      <p>
        Dipl.-Ing. Jürgen Kurzmann (groupnet.at) <br />
        Marktgasse 37/3/R1, 1090 Wien <br />
        office@groupnet.at <br />
      </p>
      <p>
        UID-Nummer: ATU64696911 <br />
        Behörde gem. ECG: Magistratisches Bezirksamt des XXIII. Bezirkes WKO:
        <a
          href="https://firmen.wko.at/dipl-ing-jürgen-kurzmann-groupnetat/wien/?firmaid=85c933ee-3907-4cf7-8453-0174b553ab59"
          >groupnet.at</a
        >
      </p>
      <h4>{{ $t('imprint.copyright') }}</h4>
      <p>{{ $t('imprint.copyright_text1') }}</p>
      <p>{{ $t('imprint.copyright_text2') }}</p>

      <h4>{{ $t('imprint.photoDisclaimer') }}</h4>
      <ul>
        <li>Banner/Demo etc.: Lunatictm - stock.adobe.com</li>
        <li>Karten-Illustrationen: Lunatictm - stock.adobe.com</li>
        <li>Inhaber: Kitera Dent – unsplash.com</li>
        <li>Disponent: Prostock-studio – stock.adobe.com</li>
        <li>Fahrer: pikselstock – stock.adobe.com</li>
        <li>Kunde: nenetus – stock.adobe.com</li>
        <li>Buchhaltung: Brooke Cagle on unsplash.com</li>
      </ul>

      <h3>{{ $t('imprint.privacy') }}</h3>
      <p>{{ $t('imprint.privacy_text1') }}</p>

      <h4>1. {{ $t('imprint.contact') }}</h4>
      <p>{{ $t('imprint.contact_text1') }}</p>

      <h4>2. {{ $t('imprint.cookies') }}</h4>
      <p>{{ $t('imprint.cookies_text1') }}</p>
      <p>{{ $t('imprint.cookies_text2') }}</p>

      <h4>3. {{ $t('imprint.bots') }}</h4>
      <p v-html="$tLink('imprint.bots_text1')" />
      <p v-html="$tLink('imprint.bots_text2')" />
      <p v-html="$tLink('imprint.bots_text3')" />
    </div>
  </div>
</template>

<script lang="js">
import { Component, Vue } from 'vue-property-decorator'

@Component({
  components: {},
})
export default class Imprint extends Vue {}
</script>
